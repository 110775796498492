import React, { useState, useEffect } from 'react';
import Vink from 'models/Vink';

function DataFormatTableComponent({
    resourceBlob,
    contentType,
    issues,
  }: {
    resourceBlob: any; 
    contentType: any; 
    issues: any; 
    }) {
    const [tableHTML, setTableHTML] = useState<null | string>(null);



  useEffect(() => {
    const tableGenerator = new Vink();

    // Generate the table HTML based on the conditions
    tableGenerator
    .generateTable(resourceBlob, contentType, issues)
    .then((generatedTableHTML) => {
      console.log('Generated table HTML:', generatedTableHTML);
      setTableHTML(generatedTableHTML);
    })
    .catch((error) => {
      console.error('Error generating table:', error);
    });
}, [resourceBlob, contentType, issues]);

  return (
    <div className="container">
      {/* Render the table if it's generated */}
      {tableHTML && (
        <div className='container'>
          <h2>Data Table View</h2>
          <div dangerouslySetInnerHTML={{ __html: tableHTML }} />
        </div>
      )}
    </div>
  );
}

export default DataFormatTableComponent;
