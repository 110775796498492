import React, { Component } from 'react';
import { random } from 'lodash'; // You might need to install and import lodash for random utility

interface Props {
    structureDef?: string;
}

interface State {
    parsedStructure: any;
    sampleData: any;
}
interface Element {
    code: any;
    id: string;
    min: number;
    //code: string[];
    type: Element[];
  }
  
  interface SampleData {
    resourceType: string;
    [key: string]: any;
}
  
function sample<T>(arr: T[]): T {
    const randomIndex = Math.floor(Math.random() * arr.length);
    return arr[randomIndex];
}

class StructureDefinitionAPI extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            parsedStructure: null,
            sampleData: null
        };
    }

    componentDidMount() {
        if (this.props.structureDef) {
            this._loadStructureDefinition(this.props.structureDef);
        }
    }

    async _loadStructureDefinition(structureDef: string) {
        try {
            const defFile = `/sd/${structureDef}.json`;
            console.log("definition file: " + defFile);
            const response = await fetch(defFile);
            const data = await response.json();
    
            // Log the first element of the snapshot to verify its properties
            console.log("First element of snapshot:", JSON.stringify(data.snapshot.element[0]));
    
            const { type } = data;
            const parsedStructure = this._parseStructure(data.snapshot.element);
            const sampleData = this._generateSampleData(parsedStructure, type);
            this.setState({ parsedStructure, sampleData });
        } catch (error) {
            console.error("Error loading structure definition:", error);
        }
    }

    _parseStructure(structure: any[]): Element[] {
        // Filter out base elements like Patient or Patient.id which don't provide specific types
        const usefulElements = structure.filter(e => e.type && e.type.length);
    
        return usefulElements.map(e => {
            return {
                id: e.id,
                min: e.min,
                code: e.type[0].code,
                type: e.type[0].targetProfile ? this._parseStructure(e.type) : [] // Recursive call if there's a targetProfile
            };
        });
    }
    
    
    

    _generateSampleData(parsedStructure: Element[], resourceType: string): SampleData {
        const sampleData: SampleData = { resourceType };
    
        // const navigateAndAssign = (baseObj: any, pathParts: string[], data: any) => {
        //     let currentObj = baseObj;
        //     for (let i = 1; i < pathParts.length - 1; i++) {
        //         const part = pathParts[i];
        //         if (!currentObj[part]) {
        //             currentObj[part] = {};
        //         }
        //         currentObj = currentObj[part];
        //     }
        //     const key = pathParts[pathParts.length - 1];
        //     if (Array.isArray(data) || (typeof data === 'object' && data !== null && !Array.isArray(data) && !(data instanceof Date))) {
        //         currentObj[key] = Array.isArray(currentObj[key]) ? [...currentObj[key], data] : [data];
        //     } else {
        //         currentObj[key] = data;
        //     }
        // };
        const navigateAndAssign = (baseObj: any, pathParts: string[], data: any) => {
            let currentObj = baseObj;
            for (let i = 1; i < pathParts.length; i++) {
                const part = pathParts[i];
                if (!currentObj[part] || typeof currentObj[part] !== 'object') {
                    currentObj[part] = {};
                }
                if (i === pathParts.length - 1) {
                    if (Array.isArray(data) || (typeof data === 'object' && data !== null && !(data instanceof Date))) {
                        currentObj[part] = Array.isArray(currentObj[part]) ? [...currentObj[part], ...data] : [data];
                    } else {
                        currentObj[part] = data;
                    }
                } else {
                    currentObj = currentObj[part];
                }
            }
        };
        
    
        const isParentRequired = (pathParts: string[]): boolean => {
            for (let i = pathParts.length - 2; i >= 1; i--) {
                const parentPath = pathParts.slice(0, i + 1).join('.');
                const parentElement = parsedStructure.find(el => el.id === parentPath);
                if (parentElement && parentElement.min === 0) {
                    return false;
                }
            }
            return true;
        };
    
        parsedStructure.forEach((element: Element) => {
            const pathParts = element.id.split('.');
            if (element.min > 0 && isParentRequired(pathParts)) {
                if (element.type && element.type.length) {
                    // If it's a complex type
                    const nestedData = this._generateSampleData(element.type, resourceType);
                    if (nestedData) {
                        navigateAndAssign(sampleData, pathParts, nestedData);
                    }
                } else {
                    const mockData = this._mockDataForField(element.id, resourceType);
                    navigateAndAssign(sampleData, pathParts, mockData);
                }
            }
        });
    
        return sampleData;
    }
    
    
    
    
    

    
    
    
    private _parentIsRequired(element: Element, parsedStructure: Element[]): boolean {
        const pathParts = element.id.split('.');
        for (let i = 0; i < pathParts.length - 1; i++) {
            const parentPath = pathParts.slice(0, i + 1).join('.');
            const parentElement = parsedStructure.find(e => e.id === parentPath);
            if (parentElement && parentElement.min === 0) {
                console.log(`Parent not required for element ${element.id} due to ${parentPath}`);
                return false;
            }
        }
        return true;
    }
    
    
    
    
    _setValueForPath(obj: any, path: string, value: any, resourceType: string) {
        const keys = path.split('.');
        let current = obj;
    
        keys.forEach((key, index) => {
            if (key === resourceType && index === 0) {
                // Skip the top-level resource type, as it's already set
                return;
            }
            if (!current[key]) {
                if (index === keys.length - 1) {
                    current[key] = value;
                } else {
                    current[key] = {};
                }
            } else if (typeof current[key] === 'string' && index !== keys.length - 1) {
                // Overwrite the string value with an object if more keys are left in the path
                current[key] = {};
            }
            current = current[key];
        });
    }
    
    
    
    
    _mockDataForField(path: string, resourceType: string): any {
        const parts = path.split('.');
        const lastPart = parts[parts.length - 1];
    
        switch (lastPart) {
            case 'id':
                return Math.random().toString(36).substring(2, 10);
            case 'implicitRules':
            case 'system':
                return 'http://example.com';
            case 'language':
                return 'en';
            case 'value':
                return 'test_value';
            case 'use':
                if (resourceType === 'name' || resourceType === 'telecom') {
                    return ['usual', 'official', 'temp'][Math.floor(Math.random() * 3)];
                }
                break;
            case 'gender':
                return ['male', 'female', 'other'][Math.floor(Math.random() * 3)];
            case 'birthDate':
                return `${1900 + Math.floor(Math.random() * 122)}-${1 + Math.floor(Math.random() * 12)}-${1 + Math.floor(Math.random() * 28)}`;
            case 'city':
                return 'Los Angeles';
            case 'district':
                return 'Central District';
            case 'state':
                return 'CA';
            case 'postalCode':
                return '90001';
            case 'country':
                return 'US';
            case 'identifier':
                return {
                    use: this._mockDataForField('use', 'identifier'),
                    system: this._mockDataForField('system', 'identifier'),
                    value: this._mockDataForField('value', 'identifier')
                };
            case 'name':
                return {
                    family: "Doe",
                    given: ["John"]
                };
            case 'telecom':
                return {
                    system: 'phone',
                    value: '555-555-5555',
                    use: this._mockDataForField('use', 'telecom')
                };
            case 'address':
                return {
                    city: this._mockDataForField('city', 'address'),
                    state: this._mockDataForField('state', 'address'),
                    postalCode: this._mockDataForField('postalCode', 'address'),
                    country: this._mockDataForField('country', 'address')
                };
            default:
                return 'sample_data';
        }
    }
    
    

       

    



    render() {
        return (
            <div>
                {/* Sample representation of the data. */}
                <pre>{JSON.stringify(this.state.sampleData, null, 2)}</pre>
            </div>
        );
    }
}

export default StructureDefinitionAPI;
