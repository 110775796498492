import xml2js from 'xml2js';

class Vink {
  async generateTable(resourceBlob: any, contentType: any, issues: any) {
    if (issues.length === 0) {
      if (contentType.toUpperCase() === 'JSON') {
        return this.generateJsonTable(resourceBlob);
      } else if (contentType.toUpperCase() === 'XML') {
        return this.generateXmlTable(resourceBlob);
      } else {
        throw new Error('Unsupported content type');
      }
    } else {
      return null; 
    }
  }

  
  private generateJsonTable(data: any): string {
    try {
      data = JSON.parse(data);
    } catch (error) {
      console.error('Error parsing JSON:', error);
      return ''; // Return an empty string in case of a parsing error
    }
  
    const generateTableRows = (obj: any, parentKey = ''): string => {
      let rows = '';
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          const currentKey = parentKey ? `${parentKey}.${key}` : key;
  
          if (typeof value === 'object') {
            // If the value is an object or array, recursively generate a nested table
            rows += generateTableRows(value, currentKey);
          } else {
            // If the value is a primitive type, add it to the table
            rows += `<tr><td>${currentKey}</td><td><pre><code>${value}</code></pre></td></tr>`;
          }
        }
      }
      return rows;
    };
  
    const tableRows = generateTableRows(data);
    const table = `<table><tr><th>Key</th><th>Value</th><th>Sample Data</td></tr><tbody>${tableRows}</tbody></table>`;
    return table;
  }
  
  
  
  

  
  private async generateXmlTable(xmlData: string): Promise<string> {
    try {
      const parser = new xml2js.Parser({ explicitArray: false, mergeAttrs: true });
      const jsonData = await parser.parseStringPromise(xmlData);
      return this.generateJsonTable(jsonData);
    } catch (error) {
      throw new Error('Failed to parse XML data');
    }
  }

  
  private getSampleData(value: any): string {
    if (typeof value === 'string') {
      return 'Sample String';
    } else if (typeof value === 'number') {
      return '42';
    } else if (typeof value === 'boolean') {
      return 'true';
    } else if (Array.isArray(value)) {
      return 'Array';
    } else if (typeof value === 'object') {
      return 'Object';
    } else {
      return 'Unknown';
    }
  }
}




export default Vink;